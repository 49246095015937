$(document).ready(function(){
	const browser=browserCheck();
	const os=osCheck();
	const device=deviceCheck();
	$("body").addClass(browser).addClass(os);


  // <a href="#***">の場合、スクロール処理を追加
  $('a[href*="#"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
  		let $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name="' + this.hash.slice(1) +'"]');
      if ($target.length) {
      	const targetOffset = $target.offset().top;
        $('html,body').animate({ scrollTop: targetOffset }, 800, 'easeOutExpo');//fast
        //$('html,body').animate({ scrollTop: targetOffset }, 1000, 'easeInOutQuart');//smooth
        /*$('html, body').animate({scrollTop: targetOffset/5,opacity: 0}, 300, function() {
					$('html, body').animate({opacity: 1,scrollTop: targetOffset}, 600);
				});//fade scroll*/
        return false;
      }
    }
  });
  
  //Menu
  $("#menu-btn").click(function(){
	  $("#menu").toggleClass("open");
  });
  
  
  //inView
  /*$('.anm').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('is-show');
    } else {
    //表示領域から出た時
     	//$(this).removeClass('is-show');
    }
  });*/
  
  //matchHeight
  /*if($(".mh").length){
		$(".mh").matchHeight();
	}*/
  
  //scroll
  /*$(window).scroll(function () {
   	const body = window.document.body;
	 	const html = window.document.documentElement;
	 	const scrTop = body.scrollTop || html.scrollTop;
	 	const scrBottom = html.scrollHeight - html.clientHeight - scrTop;
	 	if(scrTop > 300){
			
		}
		if(scrBottom >= 0){
		
		}
  });*/
  
  
  //GoogleMapAPI
	/*if($("#gmap").length){
		const styles=[];
		googlemap({
			"wrapid" : "gmap",
			"tit" : "会社名",
			"styles" : styles,
			"lat" : 0,
			"lng" : 0,
			"maplat" : 0, //mapの中心
			"maplng" : 0, //mapの中心
			"zoom" : 17,
			"infoshow" : false,
			"window" : "<dl id='infowin'><dt>会社名</dt><dd>会社の住所</dd></dl>",
			"icon" : "common/images/gmap_marker.png",
			"iconw" : 50,
			"iconh" : 50
		});
	}*/
	
	//exValidation
	if($(".validationform").length){
		/*inputの下にエラー
		const validation = $(".validationform").exValidation({
			errInsertPos :"after",
			errPosition :"fixed",
		  stepValidation: true,
		  errHoverHide:false,
		  errTipCloseBtn:false,
		  scrollAdjust:0,
		  errMsgPrefix : ""
		});*/
		
		/*エラー吹き出し表示*/
		const validation = $(".validationform").exValidation({
		  stepValidation: true,
		  errHoverHide:true,
		  errTipCloseBtn:false,
		  scrollAdjust:0,
		  errMsgPrefix : ""
		});
	}
	
	
});

//画面サイズごとの処理
$(window).on('load resize', function(){
	const w = $(window).width();
	deviceFunc(w);
});

function deviceFunc(w){
	const bp = [559,959,1280];
	if (w <= bp[0]) {
		//sp
		$(".tel-number").each(function(){
			if(!$(this).parent(".tel-wrap").length){
				const no = $(this).data("tel");
				$(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
			}
		});
  } else if(w <= bp[1]){
		//tb
		$(".tel-wrap .tel-number").each(function(){
			$(this).unwrap();
		});
  } else if(w <= bp[2]){
		//small pc
  } else{
  
  }
}
//GoogleMapAPI
function googlemap(opt){
	const latlng = new google.maps.LatLng(opt.lat,opt.lng);
	const maplatlng = new google.maps.LatLng(opt.maplat,opt.maplng);
  const myOptions = {
    zoom: opt.zoom, /*拡大比率*/
    center: maplatlng, /*表示枠内の中心点*/
    tilt:0,
    styles:opt.styles,
    scrollwheel: false,
    mapTypeControl:false,
    mapTypeId: google.maps.MapTypeId.ROADMAP/*表示タイプの指定*/
  };
  const map = new google.maps.Map(document.getElementById(opt.wrapid), myOptions);
 
	/*アイコン設定▼*/
	const iconimage = {
		url:opt.icon,
		scaledSize : new google.maps.Size(opt.iconw,opt.iconh),
		origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(opt.iconw/2, opt.iconh/2)
	}
	
  const markerOptions = {
    position: latlng,
    map: map,
    title: opt.tit,
    icon:iconimage
  };
  const marker = new google.maps.Marker(markerOptions);
　/*アイコン設定ここまで▲*/

	/*情報ウィンドウ*/
  const contentString=opt.window;
  const infowindow=new google.maps.InfoWindow({
      content: contentString
  });
  if(opt.infoshow){
	  infowindow.open(map,marker);//表示
	}
  /* マーカーがクリックされた時に情報ウィンドウ表示 */
  google.maps.event.addListener(marker, 'click', function() {
    infowindow.open(map,marker);
  });
}

/*ブラウザ判別*/
function browserCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	const av = window.navigator.appVersion.toLowerCase();
	if(ua.indexOf('edge') != -1){
		return 'edge';
	}else if (ua.indexOf('opera') != -1) {
  	return 'opera';
	}else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
		$("body").addClass('ie');
  	if (av.indexOf("msie 6.") != -1) {
    	return 'ie6';
  	} else if (av.indexOf("msie 7.") != -1) {
    	return 'ie7';
  	} else if (av.indexOf("msie 8.") != -1) {
    	return 'ie8';
  	} else if (av.indexOf("msie 9.") != -1) {
    	return 'ie9';
  	}else if (av.indexOf("msie 10.") != -1) {
    	return 'ie10';
  	}else if (av.indexOf("rv:11.") != -1) {
    	return 'ie11';
  	}
	} else if (ua.indexOf('chrome') != -1) {
  	return 'chrome';
	} else if (ua.indexOf('safari') != -1) {
  	return 'safari';
	} else if (ua.indexOf('gecko') != -1) {
  	return 'firefox';
	} else {
  	return false;
	}
}

/*OS判別*/
function osCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if (ua.indexOf('windows') != -1) {
		if(ua.indexOf('phone') != -1){
			return 'winphone';
		}else{
			return 'win';
		}
	}else if(ua.indexOf('iphone') != -1) {
		return 'iphone';
	}else if(ua.indexOf('ipad') != -1) {
		return 'ipad';
	}else if(ua.indexOf('mac') != -1) {
		return 'mac';
	}else if(ua.indexOf('android') != -1) {
		if(ua.indexOf('mobile') != -1){
			if(ua.indexOf('A1_07') != -1){
				return 'tablet';
			}else if(ua.indexOf('SC-01C') != -1){
				return 'tablet';
			}else{	return 'android';}
		}else{
			return 'tablet';
		}
	}else{
		return false;
	}
}

/*デバイス判別*/
function deviceCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if((ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) 
      || ua.indexOf("ipad") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
      || ua.indexOf("kindle") != -1
      || ua.indexOf("silk") != -1
      || ua.indexOf("playbook") != -1){
      return 'tab';
    }else if((ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
      || ua.indexOf("iphone") != -1
      || ua.indexOf("ipod") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
      || ua.indexOf("blackberry") != -1){
	    return 'sp';
    }else{
      return 'pc';
    }
}